import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;

exports = function reverse(src) {
  var buffer = new Buffer(src.length);

  for (var i = 0, j = src.length - 1; i <= j; ++i, --j) {
    buffer[i] = src[j];
    buffer[j] = src[i];
  }

  return buffer;
};

export default exports;